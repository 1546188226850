<template>
  <v-container
    class="drawer-detection-container"
    fluid
    :class="drawer ? 'drawer-open' : 'drawer-closed'"
  >
    <v-row>
      <v-col>
        <v-card>
          <v-data-table
            class="user-invitations-table"
            :headers="headers"
            :items="displayUserInvitations"
            :loading="currentPageLoading"
            :server-items-length="totalCount"
            @update:options="paginate($event)"
            :footer-props="footerProps"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>My Invitations</v-toolbar-title>
                <ConfirmationDialog ref="confirmationDialog" />
              </v-toolbar>
            </template>

            <template v-slot:[`item.host`]="{ item }">
              <div>
                {{item.host.name}}
              </div>
            </template>

            <template v-slot:[`item.roles`]="{ item }">
              <HostRoles :host-roles="item.roles" />
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div class="invitation-actions d-flex align-center justify-center" v-if="!item.hostMembership">
                <v-btn
                  x-small
                  class="invitation-decline-btn white--text"
                  color="red"
                  @click="openDeclineInvitationDialog(item)"
                >Decline</v-btn>
                <v-btn
                  x-small
                  class="invitation-accept-btn white--text ml-4"
                  color="green"
                  @click="openAcceptInvitationDialog(item)"
                >Accept</v-btn>
              </div>
              <div v-else class="text-center invitation-accepted">
                <span class="green--text">Accepted</span>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { Vue } from "vue-property-decorator";
import { dismissAction, logAndExtractMessage, paginateTable } from "@/utils/utils";
import HostRoles from "@/components/custom-components/HostRoles";

export default {
  name: "UserInvitations",

  components: {
    HostRoles,
    ConfirmationDialog: () => import("@/components/dialogs/ConfirmationDialog"),
  },

  data: () => ({
    localStorageTableSizeKey: "my.invitations.table.size",
    tableInited: false,

    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },

    headers: [
      { text: "Roles", value: "roles", sortable: false },
      { text: "Host", value: "host", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
  }),

  computed: {
    ...mapState("userInvitationsModule", [
      "currentPageLoading",
      "currentPage",
      "displayUserInvitations",
      "totalCount",
    ]),
    ...mapState("coreModule", ["drawer"]),
  },

  methods: {
    paginate(data) {
      data.itemsPerPage = paginateTable(
        data,
        this.localStorageTableSizeKey,
        this.tableInited,
      );
      const currentPage = {
        index: data.page - 1,
        size: data.itemsPerPage,
      };

      this.loadPage(currentPage);
      this.tableInited = true;
    },

    async loadPage(currentPage) {
      try {
        await this.$store.dispatch("userInvitationsModule/loadPage", currentPage);
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }
    },

    openAcceptInvitationDialog(invitation) {
      this.$refs.confirmationDialog.openDialog({
        header: "Accept confirmation",
        text: `Are you sure you want to accept invitation for ${invitation.roles.map(it => it.text).join(", ")} of "${invitation.host.name}"?`,
        submitText: "Accept",
        submitColor: "green",
        onSubmit: async () => {
          try {
            await this.$store.dispatch("userInvitationsModule/acceptUserInvitation", invitation);
            await this.$store.dispatch("userModule/loadUserData");
          } catch (e) {
            Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
          }
        },
      });
    },

    openDeclineInvitationDialog(invitation) {
      this.$refs.confirmationDialog.openDialog({
        header: "Decline confirmation",
        text: `Are you sure you want to decline invitation for ${invitation.roles.map(it => it.text).join(", ")} of "${invitation.host.name}"?`,
        submitText: "Decline",
        submitColor: "red",
        onSubmit: async () => {
          try {
            await this.$store.dispatch("userInvitationsModule/declineUserInvitation", invitation);
          } catch (e) {
            Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
          }
        },
      });
    },
  },
};
</script>
